import { ApiPromise, WsProvider } from '@polkadot/api';
import React, { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Navigate, Route, HashRouter as Router, Routes } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import PoapPage from './components/PoapPage';
import BulletinBoard from 'components/BulletinBoard';
import { displayErrorToast } from 'components/NotificationToast';
import PostPage from 'components/PostPage';

import './App.css';
import { ErrorToastMessages } from 'shared/constants/index';
import formatChainStringToNumber from 'utils/formatChainStringToNumber';

import store from './redux/store';
import AdminPage from 'pages/AdminPage/AdminPage';

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID ?? '';

export type ApiPromiseType = ApiPromise | null;

const ProtectedRoute = ({
  isAllowed,
  children,
  redirectPath,
}: {
  isAllowed: boolean;
  children: JSX.Element;
  redirectPath: string;
}): JSX.Element => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

const App = (): JSX.Element => {
  const [api, setApi] = useState<ApiPromise | null>(null);
  const [, setLastChainBlock] = useState<number | null>(null);
  const [, setLastlastBlockParent] = useState<string | null>(null);

  useEffect(() => {
    const setupProvider = async () => {
      const wsProvider = new WsProvider(process.env.REACT_APP_PROVIDER_URL);
      const wsApi = await ApiPromise.create({ provider: wsProvider, throwOnConnect: true });

      if (!wsApi) return;
      // eslint-disable-next-line no-console
      console.log(`Successfully connected to: ${process.env.REACT_APP_PROVIDER_URL}`);
      setApi(wsApi);

      await wsApi.rpc.chain.subscribeNewHeads((lastHeader) => {
        const lastBlock = formatChainStringToNumber(JSON.stringify(lastHeader.number.toHuman()));
        setLastChainBlock(lastBlock);
        setLastlastBlockParent(lastHeader.parentHash.toRawType);
      });
    };

    setupProvider().catch((error) => {
      displayErrorToast(ErrorToastMessages.ERROR_API_CONN);
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }, []);

  return (
    <ReduxProvider store={store}>
      {/* <IntercomProvider appId={INTERCOM_APP_ID} autoBoot> */}
      <Router basename="/">
        <Routes>
          {/* <Route path="/your-nft" element={<BulletinBoard api={api} />} /> */}
          {/* <Route path="/post" element={<PostPage api={api} />} /> */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute
                isAllowed
                // ={false}
                redirectPath="/mint-poap"
              >
                <AdminPage api={api} />
              </ProtectedRoute>
            }
          />
          <Route path="/mint-poap" element={<PoapPage api={api} />} />
          <Route path="/" element={<PoapPage api={api} />} />
          <Route path="*" element={<Navigate to="/mint-poap" replace />} />
        </Routes>
      </Router>
      {/* </IntercomProvider> */}
    </ReduxProvider>
  );
};

export default App;

import { ApiPromise } from '@polkadot/api';
import { ContractPromise } from '@polkadot/api-contract';

import { displayErrorToast } from 'components/NotificationToast';

import { ErrorToastMessages, readOnlyGasLimit } from 'shared/constants';

import psp34StandardMetadata from '../metadata/metadata_psp34_standard.json';
import addresses from '../metadata/addresses.json';
import { getDataFromOutput } from './getDataFromOutput';
// psp34Enumerable::ownersTokenByIndex
export const getTokenIdOfOwnerByIndex = async (
  api: ApiPromise,
  ownerAddress: string,
  index: number,
): Promise<string | null> => {
  let data = null;
  const gasLimit = readOnlyGasLimit(api);

  const contract = new ContractPromise(api, psp34StandardMetadata, addresses.pmp_psp34_address);

  const { result, output } = await contract.query['psp34Enumerable::ownersTokenByIndex'](
    contract.address,
    {
      gasLimit,
    },
    ownerAddress,
    index,
  );

  if (result.isOk && output) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data = getDataFromOutput<string>(output.toHuman())?.Ok?.U64?.replaceAll(',', '') as number;
  }

  if (result.isErr) {
    console.log(result.toHuman());
    displayErrorToast(ErrorToastMessages.ERROR_FETCHING_DATA);
  }
  return data;
};

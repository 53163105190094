import { createSlice, current } from '@reduxjs/toolkit';

import { User } from 'utils/getUsers';

interface InitialState {
  users: User[];
}

const initialState: InitialState = {
  users: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // addPost: (state, action) => {
    //   state.posts.push(action.payload);
    // },
    setAllUsers: (state, action) => {
      state.users.splice(0, current(state).users.length);
      state.users.push(...action.payload);
    },
    updateUser: (state, action) => {
      const updatedArray = state.users.map((user) => {
        if (user.walletAddress === action.payload?.walletAddress) {
          return {
            ...user,
            nftId: action.payload?.nftId,
            nftReceived: action.payload?.nftReceived,
          };
        }

        return user;
      });

      state.users = updatedArray;
    },
  },
});

export const {
  // addPost,
  updateUser,
  setAllUsers,
} = usersSlice.actions;
export default usersSlice.reducer;

import axios, { AxiosError } from 'axios';
import { ApiPromise } from '@polkadot/api';
import { ContractPromise } from '@polkadot/api-contract';

import { displayErrorToast } from 'components/NotificationToast';

import { ErrorToastMessages, readOnlyGasLimit } from 'shared/constants';

import { InjectedAccountWithMeta } from 'redux/slices/walletAccountsSlice';
import bulletinBoardMetadata from '../metadata/metadata_bulletin_board.json';
import addresses from '../metadata/addresses.json';
import { getDataFromOutput } from './getDataFromOutput';
import { useState } from 'react';
import camelize from 'camelize-ts';
import { User } from './getUsers';

function handleAxiosError(error: AxiosError | unknown) {
  if (axios.isAxiosError(error) && error.response) {
    console.log(error.response.statusText);
    displayErrorToast(ErrorToastMessages.ERROR_API_CONN);
  } else if (error instanceof Error) {
    console.log(error.message);
  } else {
    console.log('Unknown error occurred.');
  }
}

export type GetUsersProp = {
  count: number;
  items: User[];
} | null;

export const submitReceiveAddress = async ({
  requestAddress,
  signature,
}: {
  requestAddress: string;
  signature: string;
}): Promise<User | null> => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('wallet_address', requestAddress);
  urlencoded.append('signature', signature);

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BE_API}/users`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: urlencoded,
  };
  try {
    const { data, status, statusText } = await axios.request(config);


    if (status === 201 && statusText === 'Created') {
      return camelize(data) as User;
    }
  } catch (err) {
    handleAxiosError(err);
  }

  return null;
  // let data = null;
  // const gasLimit = readOnlyGasLimit(api);

  // const contract = new ContractPromise(
  //   api,
  //   bulletinBoardMetadata,
  //   addresses.bulletin_board_address,
  // );

  // const { result, output } = await contract.query.getPostsAuthors(contract.address, {
  //   gasLimit,
  // });

  // if (result.isOk && output) {

  //   data = getDataFromOutput<string[]>(output.toHuman());
  // }

  // if (result.isErr) {
  //   console.log(result.toHuman());
  //   displayErrorToast(ErrorToastMessages.ERROR_FETCHING_DATA);
  // }
  // return data;
};

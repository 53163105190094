import axios, { AxiosError } from 'axios';
import camelize from 'camelize-ts';

import { displayErrorToast } from 'components/NotificationToast';

import { ErrorToastMessages } from 'shared/constants';

import { User } from './getUsers';

function handleAxiosError(error: AxiosError | unknown) {
  if (axios.isAxiosError(error) && error.response) {
    console.log(error.response.statusText);
    displayErrorToast(ErrorToastMessages.ERROR_API_CONN);
  } else if (error instanceof Error) {
    console.log(error.message);
    displayErrorToast(`${ErrorToastMessages.CUSTOM}: Network error!`);
  } else {
    console.log('Unknown error occurred.');
    displayErrorToast(`${ErrorToastMessages.CUSTOM}: Unknown error occurred!`);
  }
}

export type GetUsersProp = {
  count: number;
  items: User[];
} | null;

export const submitUpdateBe = async ({
  id,
  walletAddress,
  nftReceived,
  nftId,
}: {
  id: string;
  walletAddress: string;
  nftId: string;
  nftReceived: boolean;
}): Promise<User | null> => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('wallet_address', walletAddress);
  urlencoded.append('nft_received', nftReceived.toString());
  urlencoded.append('nft_id', nftId);

  const config = {
    method: 'PATCH',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BE_API}/users/${id}`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: urlencoded,
  };
  try {
    const { data, status, statusText } = await axios.request(config);

    if (status === 201 && statusText === 'Created') {
      return camelize(data) as User;
    }
  } catch (err) {
    handleAxiosError(err);
  }

  return null;
};

const client = async (method, url, options = {}, baseURL = process.env.REACT_APP_API_BASE_URL) => {
  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const urlencodedOptions = new URLSearchParams(Object.entries(options)).toString();

  const { data } = await axios({
    baseURL,
    url,
    method,
    headers,
    data: urlencodedOptions,
  });

  if (data?.status === 'FAILED') {
    console.log('error FAILED @ xx>>', url, data?.message);
  }

  return data;
};
export const askBeUpdateNftData = async (options: {
  collection_address: string;
  token_id: string;
}) => {
  const ret = await client('POST', '/updateNFT', options);
  return ret;
};

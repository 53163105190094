import axios, { AxiosError } from 'axios';
import { ApiPromise } from '@polkadot/api';
import { ContractPromise } from '@polkadot/api-contract';

import { displayErrorToast } from 'components/NotificationToast';

import { ErrorToastMessages, readOnlyGasLimit } from 'shared/constants';

import bulletinBoardMetadata from '../metadata/metadata_bulletin_board.json';
import addresses from '../metadata/addresses.json';
import { getDataFromOutput } from './getDataFromOutput';
import { useState } from 'react';
import camelize from 'camelize-ts';

function handleAxiosError(error: AxiosError | unknown) {
  if (axios.isAxiosError(error) && error.response) {
    console.log(error.response.statusText);
  } else if (error instanceof Error) {
    console.log(error.message);
  } else {
    console.log('Unknown error occurred.');
  }
}

export type User = {
  id: string;
  walletAddress: string;
  nftId: number;
  nftReceived: boolean;
};
const config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: `${process.env.REACT_APP_BE_API}/users`,
  headers: {},
};

export type GetUsersProp = {
  count: number;
  items: User[];
} | null;

// | Camelize<GetUsersProp>
export const getUsers = async (): Promise<GetUsersProp> => {
  try {
    const { data, status, statusText } = await axios.request(config);



    if (status === 200 && statusText === 'OK') {
      return camelize(data) as GetUsersProp;
    }
  } catch (err) {
    handleAxiosError(err);
  }

  return null;
  // let data = null;
  // const gasLimit = readOnlyGasLimit(api);

  // const contract = new ContractPromise(
  //   api,
  //   bulletinBoardMetadata,
  //   addresses.bulletin_board_address,
  // );

  // const { result, output } = await contract.query.getPostsAuthors(contract.address, {
  //   gasLimit,
  // });

  // if (result.isOk && output) {

  //   data = getDataFromOutput<string[]>(output.toHuman());
  // }

  // if (result.isErr) {

  //   displayErrorToast(ErrorToastMessages.ERROR_FETCHING_DATA);
  // }
  // return data;
};

import { ApiPromise } from '@polkadot/api';
import { ContractPromise } from '@polkadot/api-contract';

import { displayErrorToast } from 'components/NotificationToast';

import { ErrorToastMessages, readOnlyGasLimit } from 'shared/constants';

import psp34StandardMetadata from '../metadata/metadata_psp34_standard.json';
import addresses from '../metadata/addresses.json';
import { getDataFromOutput } from './getDataFromOutput';

export const getNftUri = async (api: ApiPromise, tokenId: string): Promise<string | null> => {
  let data = null;
  const gasLimit = readOnlyGasLimit(api);

  const contract = new ContractPromise(api, psp34StandardMetadata, addresses.pmp_psp34_address);

  const { result, output } = await contract.query['psp34Traits::tokenUri'](
    contract.address,
    {
      gasLimit,
      value: 0,
    },
    tokenId,
  );

  if (result.isOk && output) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data = getDataFromOutput<string>(output.toHuman());
  }

  if (result.isErr) {
    displayErrorToast(ErrorToastMessages.ERROR_FETCHING_DATA);
  }
  return data;
};

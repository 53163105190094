import React from 'react';
import styled from 'styled-components';

import { queries } from 'shared/layout';

const HeroHeadingWrapper = styled.div`
  margin-bottom: 76px;
  width: 518px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;

  & > h2 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    font-size: 34px;
    line-height: 116%;
    letter-spacing: 0.025em;
  }

  & > p {
    color: ${({ theme }) => theme.colors.gray.medium};
    font-family: 'Karla';
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.04em;
    text-align: center;
  }

  ${queries.tiny} {
    width: 100%;
  }
`;

type Variant = 'your-nft' | 'post' | 'another' | 'poap' | 'admin';

const HeroHeading = ({ variant }: { variant: Variant }) => (
  <>
    {variant === 'your-nft' && (
      <HeroHeadingWrapper>
        <h2>Your ETH Warsaw NFT</h2>
        <p>Browse your received NFTs</p>
      </HeroHeadingWrapper>
    )}

    {variant === 'admin' && (
      <HeroHeadingWrapper>
        <h2>ETH Warsaw NFT Requests List</h2>
        <p>Send NFT to user below</p>
      </HeroHeadingWrapper>
    )}

    {variant === 'post' && (
      <HeroHeadingWrapper>
        <h2>Post</h2>
        <p>Here you can post your own texts on chain. Remember to connect your wallet first!</p>
      </HeroHeadingWrapper>
    )}

    {variant === 'poap' && (
      <HeroHeadingWrapper>
        <h2>Claim your NFT</h2>
        <p>Connect your wallet, submit the account address, and get an Aleph Zero NFT proof of attendance from ETHWarsaw 2023!</p>
      </HeroHeadingWrapper>
    )}
  </>
);
export default HeroHeading;

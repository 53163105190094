import { decodeAddress, encodeAddress } from '@polkadot/keyring';
import { hexToU8a, isHex } from '@polkadot/util';

export const isValidAddress = (addr: string): boolean => {
  try {
    encodeAddress(isHex(addr) ? hexToU8a(addr) : decodeAddress(addr));

    return true;
  } catch (error) {
    return false;
  }
};

const checkIfAddressIsValid = (walletAddress?: string): boolean =>
  !(!walletAddress || walletAddress.length !== 48);

export default checkIfAddressIsValid;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import HeroHeading from 'components/HeroHeading';
import Layout from 'components/Layout';
import { AdminPost } from 'components/Post';
import { RootState } from 'redux/store';
import { queries } from 'shared/layout';

import { ApiPromiseType } from '../../App';
import { User, getUsers } from 'utils/getUsers';
import { setAllUsers, updateUser } from 'redux/slices/usersSlice';
import { getNftBalanceOfOwner } from 'utils/getNftBalanceOfOwner';
import { getTokenIdOfOwnerByIndex } from 'utils/getTokenIdOfOwnerByIndex';
import { sendNft } from 'utils/sendNft';
import { stringToU8a } from '@polkadot/util';
import { displayErrorToast } from 'components/NotificationToast';
import { ErrorToastMessages } from 'shared/constants';
import { askBeUpdateNftData, submitUpdateBe } from 'utils/submitUpdateBe';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from 'utils/isAdmin';
import addresses from '../../metadata/addresses.json';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

const BulletinBoardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  z-index: 10;
  align-items: stretch;

  ${queries.tablet} {
  }
`;

interface AdminPageProps {
  api: ApiPromiseType;
}

const AdminPage = ({ api }: AdminPageProps): JSX.Element => {
  const [refetch, setRefetch] = useState(false);
  const dispatch = useDispatch();
  const loggedAccount = useSelector((state: RootState) => state.walletAccounts.account);
  const usersList = useSelector((state: RootState) => state.users.users);

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await getUsers();
      if (res) {
        setUsers(res.items);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    dispatch(setAllUsers(users));
  }, [users, users.length, dispatch]);

  const navigate = useNavigate();

  // eslint-disable-next-line no-useless-return, consistent-return
  useEffect((): void => {
    if (!isAdmin(loggedAccount?.address)) {
      return navigate('/');
    }
  }, [loggedAccount?.address, navigate]);

  const handlePostDelete = async ({ id, walletAddress }) => {
    if (api && loggedAccount) {

      const nftBalanceOfOwner = await getNftBalanceOfOwner(api, loggedAccount?.address);

      if (parseInt(nftBalanceOfOwner ?? '0', 10) < 1) {
        displayErrorToast(`${ErrorToastMessages.CUSTOM} You are out of NFTs.`);
        return;
      }

      const nftId = await getTokenIdOfOwnerByIndex(api, loggedAccount?.address, 0);

      if (!nftId) {
        displayErrorToast(`${ErrorToastMessages.CUSTOM} Invalid Token ID. Please try again.`);
        return;
      }

      sendNft(
        api,
        loggedAccount,
        () => {
          askBeUpdateNftData({
            collection_address: addresses.pmp_psp34_address,
            token_id: nftId,
          });
          submitUpdateBe({
            id,
            walletAddress,
            nftReceived: true,
            nftId,
          });
          dispatch(
            updateUser({
              nftId,
              walletAddress,
              nftReceived: true,
            }),
          );
        },
        walletAddress,
        { u64: parseInt(nftId, 10) },
        stringToU8a(''),
      );
    }

    // if (!loggedAccount) return;
    // if (api) {
    //   deletePost(api, loggedAccount, () => {
    //     setRefetch(true);
    //   });
    // }
  };

  // const displayFullPost = (id: string) => {
  //   const postToBeDisplayed = posts.find((post) => post.author === id);
  //   if (!postToBeDisplayed) return;
  //   setPostDetailsDisplay(postToBeDisplayed);
  // };

  return (
    <>
      {/* {postDetailsDisplay && (
        <PostDetailsPopup
          post={postDetailsDisplay}
          onPopupClose={() => setPostDetailsDisplay(null)}
        />
      )} */}
      <Layout>
        <Wrapper className="wrapper">
          <HeroHeading variant="admin" />
          <BulletinBoardContainer>
            {!usersList.length && (
              <div style={{ textAlign: 'center' }}>
                <p>No records yet</p>
              </div>
            )}
            {usersList.map((user, index) => (
              <AdminPost
                index={index + 1}
                key={user?.walletAddress}
                onPostDelete={handlePostDelete}
                {...user} // displayFullPost={false}
              />
            ))}
          </BulletinBoardContainer>
        </Wrapper>
      </Layout>
    </>
  );
};

export default AdminPage;

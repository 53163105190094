import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SectionHeader from 'components/SectionHeader';
import { queries } from 'shared/layout';

import { ApiPromiseType } from '../../../App';
import { getNftBalanceOfOwner } from 'utils/getNftBalanceOfOwner';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getNftUri } from 'utils/getNftUri';
import { getTokenIdOfOwnerByIndex } from 'utils/getTokenIdOfOwnerByIndex';
import axios from 'axios';
import Post from 'components/Post';

const HighlightsListWrapper = styled.div`
  width: 100%;
  margin-top: 67px;
  display: flex;
  flex-direction: column;
  gap: 43px;

  &::before {
    content: '';
    width: calc(50% - 80px);
    position: absolute;
    left: 0;
    background: ${({ theme }) => theme.colors.background};
    height: 75px;
    z-index: 2;
  }

  &::after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    left: calc(50% - 80px);
    border-width: 75px 0 0 58px;
    border-style: solid;
    border-color: transparent transparent transparent ${({ theme }) => theme.colors.background};
    z-index: 2;
  }

  & .background-gradient {
    width: 100%;
    height: 275px;
    margin-top: -200px;
    position: absolute;
    left: 0;
    background: radial-gradient(
      50% 181.09% at 50% 181.09%,
      ${({ theme }) => theme.colors.night[150]} 55.39%,
      transparent 100%
    );
    z-index: 1;
  }

  ${queries.tiny} {
    &.no-space-right {
      margin-right: -24px;
    }

    &::before {
      width: calc(70% - 80px);
    }

    &::after {
      left: calc(70% - 80px);
    }
  }
`;

const HighlightsPlaceholder = styled.div`
  width: 100%;
  height: 147px;
  margin-bottom: 46px;
  border: 1px dashed ${({ theme }) => theme.colors.night[300]};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
`;

const HighlightsHeaderWrapper = styled.div`
  z-index: 5;
  margin-top: 58px;
`;

const BulletinBoardContainer = styled.div`
  width: 100%;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 40px;
  align-items: center;
  z-index: 10;

  ${queries.phone} {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    z-index: 10;
  }
`;

interface HighlightsListProps {
  api: ApiPromiseType;
  refetch: boolean;
}
interface NftJson {
  id: number;
  image: string;
}
const HighlightsList = ({ api, refetch }: HighlightsListProps): JSX.Element => {
  const loggedAccount = useSelector((state: RootState) => state.walletAccounts.account);

  const [nftList, setNftList] = useState<NftJson[] | null>([]);
  const [nftBalanceOwner, setNftBalanceOwner] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPosts = async () => {
      if (api && loggedAccount) {
        setLoading(true);
        const nftBalanceOfOwner = await getNftBalanceOfOwner(api, loggedAccount?.address);
        let nftIdList: number[] = [];
        console.log("nftBalanceOfOwner", nftBalanceOfOwner)
        setNftBalanceOwner(Number(nftBalanceOfOwner));
        const nftBalance = Number(nftBalanceOfOwner) < 10 ? Number(nftBalanceOfOwner) : 10;
        await Promise.all(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          [...Array(nftBalance)].map(async (_, index) => {
            const nftId = await getTokenIdOfOwnerByIndex(api, loggedAccount?.address, index);
            console.log("nftId", nftId)

            return parseInt(nftId ?? '0', 10);
          }),
        ).then((arr: number[]) => {
          console.log("arr", arr)

          nftIdList = arr;
        });

        const nftUri = await getNftUri(api, '1');
        const baseNftUri = nftUri?.replace("ipfs://", "https://ipfs.io/ipfs/");
        const baseJsonUrl = `${baseNftUri?.replace(/\/(\d+)\.json$/, '')}`;

        await Promise.all(
          nftIdList?.map(async (id) => {
            // const ret = await axios.get(`${baseJsonUrl}/${id}.json`);
            const ret = await fetch(`${baseJsonUrl}/${id}.json`).then((res) =>
              res.json()
            );

            return {
              id,
              image: ret?.image,
            };
          }),
        ).then((nftJsonList) => {

          setNftList(nftJsonList as NftJson[]);
          setLoading(false);

        }).catch(err => {
          setLoading(false);
          console.log('err', err)
        });
      } else {
        setNftList([]);
        setLoading(false);

      }
    };
    getPosts();
  }, [api, loggedAccount, loggedAccount?.address]);

  return (
    <HighlightsListWrapper>
      {/* <div className="background-gradient" /> */}
      <HighlightsHeaderWrapper>
        <SectionHeader>
          Your <span>ETH Warsaw NFT </span>
        </SectionHeader>
        <p style={{ marginTop: '16px' }}>You have total {nftBalanceOwner || 0} NFT{nftBalanceOwner > 1 ? 's' : ''}</p>
      </HighlightsHeaderWrapper>

      {loading ? (
        <HighlightsPlaceholder>
          <p>Please wait a moment ...</p>
        </HighlightsPlaceholder>
      ) : (
        <>
          {!nftList?.length && (
            <HighlightsPlaceholder>
              <p>No records yet</p>
            </HighlightsPlaceholder>
          )}


          <BulletinBoardContainer>
            {nftList?.map((n) => <Post key={n?.id} {...n} />)}
          </BulletinBoardContainer>

        </>
      )}
    </HighlightsListWrapper>
  );
};

export default HighlightsList;

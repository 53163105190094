import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import ThemeProvider from 'components/ThemeProvider';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);

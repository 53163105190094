import { ApiPromise } from '@polkadot/api';
import { ContractPromise } from '@polkadot/api-contract';
import { web3FromSource } from '@polkadot/extension-dapp';

import { displayErrorToast, displaySuccessToast } from 'components/NotificationToast';

import { InjectedAccountWithMeta } from 'redux/slices/walletAccountsSlice';
import { ErrorToastMessages } from 'shared/constants';

import psp34StandardMetadata from '../metadata/metadata_psp34_standard.json';
import addresses from '../metadata/addresses.json';
import { getGasLimit } from './dryRun';
import { stringToU8a } from '@polkadot/util';

export const sendNft = async (
  api: ApiPromise,
  loggedUser: InjectedAccountWithMeta,
  onSuccess: () => void,
  ...args
): Promise<void> => {
  if (!loggedUser.meta.source) return;

  const contract = new ContractPromise(api, psp34StandardMetadata, addresses.pmp_psp34_address);
  const injector = await web3FromSource(loggedUser.meta.source);

  const gasLimitResult = await getGasLimit(
    api,
    loggedUser.address,
    'psp34::transfer',
    contract,
    { value: 0 },
    args,
    //  receiveAddress, { u64: tokenId }, stringToU8a('')
  );

  if (!gasLimitResult.ok) {
    console.log(gasLimitResult.error);
    return;
  }
  const { value: gasLimit } = gasLimitResult;

  await contract.tx['psp34::transfer'](
    {
      gasLimit,
    },
    ...args,
  )
    .signAndSend(loggedUser.address, { signer: injector.signer }, ({ events = [], status }) => {
      events.forEach(({ event: { method } }) => {
        if (method === 'ExtrinsicSuccess' && status.type === 'InBlock') {
          displaySuccessToast({
            toastHeading: 'Successful!',
            toastParagraph: `NFT #${args[1].u64} is sent to ${args[0]}.`,
          });
          onSuccess();
        } else if (method === 'ExtrinsicFailed') {
          const errorMessage = `${ErrorToastMessages.CUSTOM} ${method}.`;
          displayErrorToast(errorMessage);
        }
      });
    })
    .catch((error) => {
      displayErrorToast(`${ErrorToastMessages.CUSTOM} ${error}.`);
    });
};

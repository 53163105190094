import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store';
import getWalletAddressShort from 'utils/getWalletAddressShort';
import catNftImg from 'assets/png/cat-nft.gif';
import { queries } from 'shared/layout';
import { User } from 'utils/getUsers';

const PostWrapper = styled.div`
   .post-bottom {
    width: 100%;
    min-height: 60px;
    padding: 4px;
    background-color: ${({ theme }) => theme.colors.night[300]};
    display: flex;
    align-items: center;
    justify-content: space-around;

    .id-no {
      font-size: 10px;
      max-width: fit-content;
      min-width: 22px;
      // margin-right: 16px;
    }

    .address {
      font-size: 10px;
      width: fit-content;
      margin-right: 16px;
    }

    .button-action {

      button {
      background-color: ${({ theme }) => theme.colors.night[300]};
        font-size: 10px;
        width: 50px;
      }
      margin-left:auto
      margin-right:auto
      width: fit-content;
      margin-right: 16px;

     }

    .button-wrap {
      button {
        font-size: 12px;
        min-width: 86px;
        margin-left:auto
        margin-right:auto
      }

      max-width: fit-content;
      min-width: 86px;
     }

    button {
      height: 36px;
      width: max-content;
      color: ${({ theme }) => theme.colors.primaryDarker};
      background: ${({ theme }) => theme.colors.button.secondary};
      border-radius: 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8.5px 16px;
      gap: 8px;
      justify-self: end;
      position: relative;
      transition:
        background-color 0.4s ease,
        opacity 0.4s ease;
      will-change: background-color, opacity;

      justify-content: start;
      display: flex;
       padding: 12px;

      &:hover {
        background: ${({ theme }) => theme.colors.button.secondaryHover};
      }
    }
  }

   ${queries.tablet} {
    width: 100%;
    height: max-content;
    border: 2px solid ${({ theme }) => theme.colors.night[300]};
    border-radius: 2px;
    display: flex;
    flex-direction: column;

    &.own-post {
      border: 2px solid ${({ theme }) => theme.colors.primary};
    }

    h3 {
      align-self: center;
      font-weight: 500;
      padding: 20px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .post-bottom {
      min-height: 60px;
      padding: 10px;
      background-color: ${({ theme }) => theme.colors.night[300]};
      display: flex;
      align-items: center;
      justify-content: space-around;

      .id-no {
        font-size: 16px;
        max-width: fit-content;
        min-width: 32px;
        margin-right: 16px;
      }

      .address {
        font-size: 16px;
        max-width: fit-content;
        min-width: 528px;
        margin-right: 16px;
      }

      .button-action {
        button {
          font-size: 16px;
          min-width: 174px;
        }
        margin-left:auto
        margin-right:auto
        min-width: 186px;
        max-width: fit-content;
      }
      .button-wrap {
        button {
          font-size: 16px;
          min-width: 86px;
          margin-left:auto
          margin-right:auto
        }

        max-width: fit-content;
        min-width: 86px;
       }
    }

    p {
      span {
        font-weight: 700;
      }
    }

    button {
      height: 36px;
      width: max-content;
      color: ${({ theme }) => theme.colors.primaryDarker};
      background: ${({ theme }) => theme.colors.button.secondary};
      border-radius: 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8.5px 16px;
      gap: 8px;
      justify-self: end;
      position: relative;
      transition:
        background-color 0.4s ease,
        opacity 0.4s ease;
      will-change: background-color, opacity;

      justify-content: start;
      display: flex;
      // gap: 24px;
      // grid-template-columns: repeat(12, 1fr);
      padding: 12px;

      &:hover {
        background: ${({ theme }) => theme.colors.button.secondaryHover};
      }
    }

    .image-wrapper {
      max-width: 600px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: -1px 0px 20px 0px black;
      }
    }
  }
`;

interface AdminUserProps extends User {
  index: number;
  onPostDelete: ({ walletAddress, id }: { id: string; walletAddress: string }) => void;
}

const AdminPost = (props: AdminUserProps): JSX.Element => {
  const { walletAddress, nftReceived, nftId, index, onPostDelete } = props;
  const loggedAccount = useSelector((state: RootState) => state.walletAccounts.account);

  // const isAuthor = loggedAccount?.address === author;
  return (
    <PostWrapper>
      <div className="post-bottom">
        <p className="id-no">
          <span># </span>
          {index}
        </p>
        <p className="address">
          {/* <span>Address:</span> {getWalletAddressShort(author)} */}
          <span>Address:</span> {getWalletAddressShort(walletAddress, 5)}
        </p>
        <div className="button-action">
          {nftReceived ? (
            <button type="button" disabled style={{ color: 'white', fontWeight: 'bold' }}>
              Received NFT #{nftId}
            </button>
          ) : (
            <button type="button" disabled>
              Pending
            </button>
          )}
        </div>
        <div className="button-wrap">
          <button disabled={nftReceived} type="button" onClick={() => onPostDelete(props)}>
            Send NFT
          </button>
        </div>
      </div>
    </PostWrapper>
  );
};

const MemoizedPost = React.memo(AdminPost);

export default MemoizedPost;

/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import HeroHeading from 'components/HeroHeading';
import Layout from 'components/Layout';

import { queries } from 'shared/layout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import WalletButton from 'components/Wallet/WalletButton/WalletButton';
import { InjectedAccountWithMeta, connectWallet } from 'redux/slices/walletAccountsSlice';
import AccountSelector from 'components/Wallet/AccountSelector/AccountSelector';
import { web3FromSource } from '@polkadot/extension-dapp';
import { stringToHex } from '@polkadot/util';
import { submitReceiveAddress } from 'utils/submitReceiveAddress';
import { displayErrorToast, displaySuccessToast } from 'components/NotificationToast';
import { getUsersByAddress } from 'utils/getUsersByAddress';
import { isValidAddress } from 'utils/checkIfAddressIsValid';
import { ErrorToastMessages } from 'shared/constants';
import { User } from 'utils/getUsers';
import HighlightsList from 'components/BulletinBoard/HighlightsList/HighlightsList';
import { ApiPromiseType } from 'App';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};

  .cards {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    justify-content: center;
    gap: 24px;

    ${queries.phone} {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      gap: 12px;
    }
  }

  .post-form {
    padding-top: 32px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;

    label {
      max-width: 420px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &.input-row {
        flex-direction: row;
      }
    }

    input {
      font-size: 13.5px;
      text-align: center;
      &#post-is-highlight {
        accent-color: ${({ theme }) => theme.colors.primary};
        width: 20px;
      }
    }

    input[type='text'],
    input[type='number'] {
      padding: 10px;
      border-radius: 10px;
    }

    p {
      margin-top: 20px;
    }

    button {
      margin-left: 4px;
      margin-right: 4px;
      height: 36px;
      width: max-content;
      color: ${({ theme }) => theme.colors.primaryDarker};
      background: ${({ theme }) => theme.colors.button.secondary};
      border-radius: 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8.5px 16px;
      gap: 8px;
      justify-self: end;
      position: relative;
      transition:
        background-color 0.4s ease,
        opacity 0.4s ease;
      will-change: background-color, opacity;
      justify-self: center;
      align-self: center;

      &:hover {
        background: ${({ theme }) => theme.colors.button.secondaryHover};
      }
    }
    .button-group {
      button {
        width: fit-content;
        min-width: 160px;
      }
      ${queries.phone} {
        button {
          width: fit-content;
          min-width: 180px;
        }
      }

      width: 70%;
      display: flex;
      justify-content: center;
    }
  }

  .container {
    ${queries.phone} {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 70%;
    }

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    .image-wrapper {
      max-width: 360px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: -1px 0px 20px 0px black;
      }
    }
  }
`;
interface PoapProps {
  api: ApiPromiseType;
}
const PoapPage = ({ api }: PoapProps): JSX.Element => {
  // const loggedAccount = useSelector((state: RootState) => state.walletAccounts.account);
  const { allAccounts, account: loggedAccount } = useSelector(
    (state: RootState) => state.walletAccounts,
  );

  const [requestAddress, setRequestAddress] = useState<string>('');

  useEffect(() => {
    if (loggedAccount?.address) {
      setRequestAddress(loggedAccount?.address);
    } else {
      setRequestAddress('');
    }
  }, [loggedAccount?.address]);
  const dispatch = useDispatch();

  const [isAccountsModalVisible, setIsAccountsModalVisible] = useState<boolean>(false);
  const onModalAccountSelection = (account: InjectedAccountWithMeta): void => {
    setIsAccountsModalVisible(false);
    dispatch(connectWallet(account));
  };
  const [receiverInfo, setReceiverInfo] = useState<User | null>(null);

  const fetchInitialData = useCallback(async () => {
    const res = await getUsersByAddress(loggedAccount?.address);

    if (res) {
      setReceiverInfo(res);
    } else {
      setReceiverInfo(null);
    }
  }, [loggedAccount?.address]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSendRequest = async (): Promise<any> => {
    
    if (!isValidAddress(requestAddress)) {
      displayErrorToast(ErrorToastMessages.NOT_VALID_ADDRESS);
      return;
    }

    if (loggedAccount?.meta?.source) {
      const { signer } = await web3FromSource(loggedAccount?.meta?.source);

      if (signer && signer?.signRaw) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { signature } = await signer?.signRaw({
          address: loggedAccount?.address,
          data: stringToHex(process.env.REACT_APP_SUBMIT_TEXT),
          type: 'bytes',
        });

        const data = {
          requestAddress,
          signature,
        };

        const res = await submitReceiveAddress(data);

        if (res?.walletAddress) {
          displaySuccessToast({
            toastHeading: 'Congras!',
            toastParagraph: `${res?.walletAddress} is submitted`,
          });

          await fetchInitialData();
        }
      }
    }

  };

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData, loggedAccount]);
  const isButtonDisabled = (): boolean =>
    !(loggedAccount && requestAddress.length) || !!receiverInfo;

  return (
    <Layout>
      <Wrapper className="wrapper">
        <HeroHeading variant="poap" />
        <div className="container">
          <div className="image-wrapper">
            <img className="image" alt="nft-cat" src={process.env.REACT_APP_BANNER_IMAGE} />
          </div>
          <div className="post-form">
            <label htmlFor="post-input">
              Wallet address:
              <input
                type="text"
                id="post-input"
                value={requestAddress}
                placeholder="5EfUA ..."
                onChange={({ target }) => setRequestAddress(target.value)}
              />
            </label>
            <div className="button-group">
              <WalletButton
                setIsAccountsModalVisible={() => setIsAccountsModalVisible(true)}
                //  setIsMobileNavClosed={onSetMobileNavClosed}
                setIsMobileNavClosed={() => {}}
                loggedAccountAddress={loggedAccount?.address}
              />{' '}
              <button type="button" disabled={isButtonDisabled()} onClick={handleSendRequest}>
                Submit
              </button>
            </div>
            <div>
              {loggedAccount && receiverInfo?.nftId
                ? 'Please check your NFT below.'
                : loggedAccount && receiverInfo && !receiverInfo?.nftId
                ? 'This address has been submitted.'
                : ''}
            </div>
          </div>
        </div>
        <HighlightsList api={api} refetch={false} />
      </Wrapper>
      {isAccountsModalVisible && (
        <AccountSelector
          accounts={allAccounts}
          onAccountSelection={(account) => onModalAccountSelection(account)}
          onModalClose={() => setIsAccountsModalVisible(false)}
        />
      )}
    </Layout>
  );
};

export default PoapPage;
